<div class="bg-card">
    <div class="flex flex-col w-full mx-auto px-6 sm:px-8">
        <div
            class="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 my-3"
        >
            <div class="flex flex-auto items-center min-w-0">
                <div
                    [skeleton]="isLoading"
                    class="flex w-16 h-16 rounded-full overflow-hidden items-center justify-center bg-slate-100 border"
                >
                    <img
                        class="w-16 h-16 rounded-full overflow-hidden"
                        *ngIf="user.profilePic"
                        [src]="user.profilePic"
                    />
                    <mat-icon
                        *ngIf="!user.profilePic"
                        class="firm-icon flex material-symbols-outlined"
                    >
                        account_circle
                    </mat-icon>
                </div>
                <div class="flex flex-col min-w-0 ml-4">
                    <div
                        [skeleton]="isLoading"
                        class="text-xl font-semibold tracking-tight leading-7 md:leading-snug truncate"
                    >
                        {{ user.firstName + ' ' + user.lastName }}
                    </div>
                    <div [skeleton]="isLoading" class="flex items-center">
                        <mat-icon class="text-lg justify-start"
                            >notifications_active</mat-icon
                        >
                        <div
                            class="ml-1.5 leading-6 truncate text-secondary font-semibold"
                            [translate]="'app.YOU_HAVE_OUTSTANDING_NOTICES'"
                            [translateParams]="{
                                number: taskInfo?.totalOutstandingNotice
                                    ? taskInfo?.totalOutstandingNotice
                                    : 0
                            }"
                        ></div>
                    </div>
                    <div [skeleton]="isLoading" class="flex items-center">
                        <mat-icon class="text-lg justify-start">rule</mat-icon>
                        <div
                            class="ml-1.5 leading-6 truncate text-secondary font-semibold"
                            [translate]="'app.YOU_HAVE_OUTSTANDING_TASK'"
                            [translateParams]="{
                                number: taskInfo?.totalOutstandingTask
                                    ? taskInfo?.totalOutstandingTask
                                    : 0
                            }"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="px-6 sm:px-8 w-screen md:w-full overflow-x-auto firm-info-toggle"
        *ngIf="!router.url.includes('profile')"
    >
        <mat-button-toggle-group
            name="favoriteColor"
            aria-label="Favorite Color"
        >
            <mat-button-toggle
                *ngIf="user.isAssociationActive"
                (click)="setTaskDetails()"
                [checked]="true"
                [value]="user.firstName"
                class="px-6 mr-3"
            >
                <div class="overflow-ellipsis truncate max-w-40 h-6">
                    {{ user.firstName + ' ' + user.lastName }}
                </div>
                <p
                    *ngIf="taskInfo?.totalOutstandingItem"
                    class="count bg-red-600 rounded-full w-5 h-5 text-white"
                >
                    {{ taskInfo?.totalOutstandingItem }}
                </p>
            </mat-button-toggle>

            <mat-button-toggle
                [disabled]="true"
                [value]="'app.YOUR_COMPANY' | translate"
                class="px-6 mr-3"
                [matTooltip]="'app.YOUR_COMPANY' | translate"
            >
                <div class="overflow-ellipsis truncate max-w-40 h-6">
                    {{'app.YOUR_COMPANY' | translate}}
                </div>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>
