import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import {SignUpGuard} from "./core/auth/guards/signUp.guard";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'home' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'home' },
    {
        path: 'token-invalid',
        loadChildren: () =>
            import('app/modules/landing/invalid-token/invalid-token.module').then(
                (m) => m.InvalidTokenModule
            )
    },
    {
        path: 'task-expired',
        loadChildren: () =>
            import('app/modules/landing/expired-task/expired-task.module').then(
                (m) => m.ExpiredTaskModule
            )
    },
    {
        path: 'revoke-access',
        loadChildren: () =>
            import('app/modules/landing/revoke-access/revoke-access.module').then(
                (m) => m.RevokeAccessModule
            )
    },

    // Auth routes for guests
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'confirmation-required',
                canMatch: [NoAuthGuard],
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.module'
                    ).then((m) => m.AuthConfirmationRequiredModule)
            },
            {
                path: 'forgot-password',
                canMatch: [NoAuthGuard],
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.module'
                    ).then((m) => m.AuthForgotPasswordModule)
            },
            {
                path: 'reset-password',
                canMatch: [NoAuthGuard],
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.module'
                    ).then((m) => m.AuthResetPasswordModule)
            },
            {
                path: 'sign-in',
                canMatch: [NoAuthGuard],
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        (m) => m.AuthSignInModule
                    )
            },
            {
                path: 'sign-up',
                canMatch: [SignUpGuard],
                loadChildren: () =>
                    import('app/modules/auth/open-sign-up/open-sign-up.module').then(
                        (m) => m.SelfSignUpModule
                    )
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        (m) => m.AuthSignUpModule
                    )
            }
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        (m) => m.AuthSignOutModule
                    )
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.module'
                    ).then((m) => m.AuthUnlockSessionModule)
            }
        ]
    },



    // Admin routes
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/admin/home/home.module').then(
                        (m) => m.HomeModule
                    )
            },
            {
                path: 'profile',
                loadChildren: () =>
                    import('app/modules/admin/profile/profile.module').then(
                        (m) => m.ProfileModule
                    )
            },
            {
                path: 'accounting',
                loadChildren: () =>
                    import(
                        'app/modules/admin/accounting/accounting.module'
                    ).then((m) => m.AccountingModule)
            },
            {
                path: 'inbox',
                loadChildren: () =>
                    import(
                        'app/modules/admin/inbox/inbox.module'
                        ).then((m) => m.InboxModule)
            }
        ]
    },

    // Wildcard route when no path matches
    { path: '**', pathMatch: 'full', redirectTo: 'home' }
];
