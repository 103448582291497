import {InboxItem} from "../../modules/admin/inbox/inbox.type";

export enum TaskEventTypesEnum {
    createTask = "CREATE_TASK",
    updateTask = "UPDATE_TASK",
    deleteTask = "DELETE_TASK",
}

export interface OutstandingCount{
    outstandingTask?: number;
    outstandingNotice?: number;
    outstandingItem: number;
}

export interface ItemEventType{
    type: string;
    taskData : Array<InboxItem>;
    totalOutstandingItem: number;
    totalOutstandingNotice: number;
    totalOutstandingTask: number;
    totalCompletedItem: number;
    totalCompletedNotice: number;
    totalCompletedTask: number;
}
