export const environment = {
    production: false,
    apiUrl: 'https://api.dev.kamigo.io',
    captchaKey: '6Ld6glglAAAAADlbxv6x6IpGMp2KaZaCJkxyiYJ-',
    enableSignUp: true,
    translationFileUrl:
        'https://flowtribe-translation.s3.eu-central-1.amazonaws.com/kamigo/app-panel/',
    sentry: {
        enabled: true,
        dsn: 'https://05e584a5f07e2b86197d596b34a1dac7@o902955.ingest.us.sentry.io/4507418745700352',
        environment: 'development'
    }
};
