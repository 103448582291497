import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import {TranslateModule} from "@ngx-translate/core";
import {SkeletonModule} from "../../@fuse/directives/skeleton-loaders/skeleton.module";
import { NgxDatePipe } from '../../@fuse/pipes/localized-date.pipe';
const customNotifierOptions: NotifierOptions = {
    position: {
        horizontal: {
            position: 'right',
            distance: 12
        },
        vertical: {
            position: 'bottom',
            distance: 12,
            gap: 10
        }
    },
    theme: 'material',
    behaviour: {
        autoHide: 5000,
        onClick: 'hide',
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        stacking: 4
    },
    animations: {
        enabled: true,
        show: {
            preset: 'slide',
            speed: 300,
            easing: 'ease'
        },
        hide: {
            preset: 'fade',
            speed: 300,
            easing: 'ease',
            offset: 50
        },
        shift: {
            speed: 300,
            easing: 'ease'
        },
        overlap: 150
    }
};
@NgModule({
    declarations:[NgxDatePipe],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NotifierModule.withConfig(customNotifierOptions),
        TranslateModule,
        SkeletonModule
    ],
    exports: [CommonModule, FormsModule, ReactiveFormsModule, NotifierModule,TranslateModule,SkeletonModule,NgxDatePipe]
})
export class SharedModule {}
