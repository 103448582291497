import { Injectable } from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import { io } from "socket.io-client";
import {AuthService} from "../auth/auth.service";
import {environment} from "../../../environments/environment";
import {ItemEventType, OutstandingCount} from './web-socket.types';

@Injectable({
    providedIn: 'root'
})
export class WebSocketService {

    public taskNotification: Subject<ItemEventType> = new Subject<ItemEventType>();
    public totalOutstandingCount: BehaviorSubject<OutstandingCount> = new BehaviorSubject<OutstandingCount>(null);
    private socket: any;

    constructor(private authService:AuthService){
        if(!this.socket?.connected && this.authService.accessToken){
            this.initSocket();
        }
    }

    public initSocket(){
        this.socket = io(environment.apiUrl, {
            auth: {
                Authorization:
                    this.authService.accessToken
            },
            transports: ["websocket","polling"],
            withCredentials: true
        });

        this.socket.on('taskEvent', (res:ItemEventType) =>{
            if((res?.taskData[0].status === 'unread' && (res?.type === 'createTask' || res?.type === 'deleteTask')) ||
                (res?.taskData[0].status === 'sent' && (res?.type === 'createTask' || res?.type === 'deleteTask')) || res?.type === 'updateTask'){
                this.totalOutstandingCount.next({outstandingNotice:res.totalOutstandingNotice,outstandingItem:res.totalOutstandingItem,outstandingTask:res.totalOutstandingTask});
            }
          this.taskNotification.next(res);
        });
    }

    public disconnectSocket(){
        this.socket.disconnect();
    }
}
