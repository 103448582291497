export const languageMappingObject = {
    en: 'english',
    nl: 'dutch',
    de: 'german',
    fr: 'french'
};

const supportedLanguages: string[] = Object.keys(languageMappingObject);

export function isLanguageAvailable(browserLanguage: string): string {
    if (supportedLanguages.includes(browserLanguage.slice(0, 2))) {
        return languageMappingObject[browserLanguage.slice(0, 2)];
    }
    return 'english';
}

export function getLanguageCode(language: string): string {
    return supportedLanguages.find(
        (key) => languageMappingObject[key] === language
    );
}
