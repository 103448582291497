<ng-container *ngIf="user.isAssociationActive; else notActiveUser">
    <div
        class="text-xl font-semibold tracking-tight leading-7 md:leading-snug truncate flex"
    >
        <div [skeleton]="isLoading" class="flex">
            <div class="pr-2">{{ 'app.ACCOUNTING' | translate }}</div>
            <div class="pr-2">-</div>
            <div>{{ firm?.firmName }}</div>
        </div>
    </div>
    <div class="firm-name mt-3">
        <ng-container [ngTemplateOutlet]="gridColumn"></ng-container>
    </div>
</ng-container>

<ng-template #gridColumn>
    <div
        class="grid grid-cols-1 md:grid-cols-4 sm:grid-cols-2 gap-8 accounting-firm-container"
    >
        <ng-container *ngIf="taskInformation?.type !== 'company'">
            <div
                class="flex flex-col flex-auto p-4 bg-card shadow rounded-xl relative cursor-pointer"
                [routerLink]="['/', 'inbox']"
            >
                <div
                    *ngIf="taskInformation?.totalOutstandingItem"
                    class="absolute -top-3 md:-right-3 right-0 w-7 h-7 flex items-center justify-center rounded-full bg-red-600 text-white"
                >
                    {{ taskInformation?.totalOutstandingItem }}
                </div>
                <h4 class="text-lg font-semibold" [skeleton]="isLoading">
                    {{ 'app.INBOX' | translate }}
                </h4>
                <p
                    [skeleton]="isLoading"
                    class="min-h-[40px] text-sm text-slate-400"
                    [translate]="'app.YOU_HAVE_OUTSTANDING_ITEMS'"
                    [translateParams]="{
                        number: taskInformation?.totalOutstandingItem
                            ? taskInformation?.totalOutstandingItem
                            : 0
                    }"
                ></p>
                <div
                    class="flex justify-center items-center"
                    [skeleton]="isLoading"
                >
                    <mat-icon
                        [svgIcon]="'heroicons_outline:inbox-in'"
                        class="text-sky-500 material-symbols-outlined text-6xl h-10 w-10 self-center my-2 justify-start"
                    >
                    </mat-icon>
                </div>
            </div>
            <div
                class="flex flex-col flex-auto p-4 bg-card shadow rounded-xl relative cursor-pointer request"
                (click)="goToLastPTI()"
                *ngIf="taskInformation?.totalOutstandingTask"
            >
                <div
                    *ngIf="!isGetLink"
                    class="absolute w-full h-full z-50 flex items-center justify-center top-0 left-0"
                    (click)="$event.stopPropagation()"
                >
                    <mat-spinner diameter="40"></mat-spinner>
                </div>
                <div
                    class="absolute -top-3 md:-right-3 w-7 h-7 right-0 flex items-center justify-center rounded-full bg-red-600 text-white"
                >
                    !
                </div>
                <h4
                    class="text-lg font-semibold pit-heading"
                    [skeleton]="isLoading"
                >
                    {{ 'app.PERSONAL_INCOME_TAX' | translate }}
                </h4>
                <p
                    class="min-h-[40px] text-sm text-slate-400"
                    [skeleton]="isLoading"
                >
                    {{ 'app.YOUR_QUESTIONNAIRE' | translate }}
                </p>
                <div
                    class="flex justify-center items-center"
                    [skeleton]="isLoading"
                >
                    <mat-icon
                        class="text-sky-500 text-6xl h-10 w-10 self-center my-2 material-symbols-outlined justify-start"
                        >description
                    </mat-icon>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>
<ng-template #notActiveUser>
    <div class="flex flex-col items-center justify-center w-full h-full">
        <div class="w-full max-w-3xl">
            <div class="max-w-none mx-auto prose prose-sm">
                <img
                    class="w-20"
                    src="assets/images/logo/logo.svg"
                    alt="Logo image"
                />
                <h1>{{ 'app.ASSOCIATED_ACCOUNTS_NOT_FOUND' | translate }}</h1>
                <p>{{ 'app.FIRM_AFFILIATION' | translate }}</p>
            </div>
        </div>
    </div>
</ng-template>
