import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app/app.module';

import * as Sentry from '@sentry/angular';
import { environment } from './environments/environment';

if (environment.sentry.enabled) {
    Sentry.init({
        dsn: environment.sentry.dsn,
        environment: environment.sentry.environment,
        integrations: []
    });
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((ref) => {
        // Ensure Angular destroys itself on hot reloads.
        if (window['ngRef']) {
            window['ngRef'].destroy();
        }
        window['ngRef'] = ref;

        // Otherwise, log the boot error
    })
    .catch((err) => console.error(err));
